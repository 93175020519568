<template>
  <div class="navmenu"  v-if='navmenu.name'>
      <!-- {{navmenu}} -->
      <!-- <p @click="jumpClassify(navmenu,0)">{{navmenu.name}} </p>
       <el-divider></el-divider> -->
      <ul class="left_nav">
      <li  v-for="(item,index) in navmenu.child" :key="index" @click="jumpClassify(item,1)"> 
        <!-- 分类图标 -->
        <span v-if="item.pic" ><img :src="item.pic" alt=""></span>
        <span v-else></span>
        <span  v-if='item.name'>{{item.name}} </span>
        <span><i class="el-icon-arrow-right"></i></span>
      </li>
    </ul>
  </div>
</template>
<script>
export default {
   name: "NavMenu",
  data() {
    return {
    };
  },
  props: ['navmenu'],
  methods: {
    
    jumpClassify(item,key) {
      if(key==0){
          const newTooltip=[];
          localStorage.setItem('newTooltip',JSON.stringify(newTooltip));
      }else{
          const newTooltip=[{"name":item.name,"show":true,"id":item.id}];
          localStorage.setItem('newTooltip',JSON.stringify(newTooltip));
      }
      localStorage.setItem('clickVal',this.navmenu.name);
      localStorage.setItem('clickID',this.navmenu.id);
      this.$router.push({path: "/productcategory", query: { ids: item.id } });
      this.$emit("hideFun",false)
      
    }
  },
  mounted() {
  },
};
</script>

<style scoped lang="less">
.navmenu{
  p{
    font-size: 16px;
    color: #333333FF;
    cursor: pointer;
     &:hover{
                color: #ff3333;
            }
  }
  .el-divider--horizontal{
    margin:16px 0 6px 0;
  }
  ul{
    li{
      // width: 24%;
      // margin: 0.5%;
      width:180px;
      margin:8px 5px;
      padding: 10px  20px;
      float: left;
      text-align: center;
      color:#3D3D3D;
      font-size: 12px;
      line-height: 40px;
      background: #F0F0F0;
      font-size:15px;
      height: 40px;
      display: flex;
      align-items: center; /* 垂直居中 */
      cursor: pointer;
            &:hover{
                // color: #ff3333;
                color: #3D3D3D;
                background:#E4E5E7 ;
            }
      span:nth-child(1){
        float: left;
        display: flex;
        // justify-content: center; /* 水平居中 */
        // align-items: center; /* 垂直居中 */
        img{
          width:24px;
          height:24px;
        }
      }
      span:nth-child(2){
        flex: 1;
        justify-content: center; 
        text-align: left;
        padding-left:15px;
      }
      span:nth-child(3){
        float: right;
        i{
          font-weight: bold;
          font-size: 16px;
        }
      }
       
    }
    li:nth-child(4n){
      text-align: right;
    }
    li:nth-child(4n+1){
      text-align: left;
    }
  }
}
</style>
